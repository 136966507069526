/** @jsx jsx */

import { jsx, Global, css } from '@emotion/react'

export default function GlobalStyling() {
	return (
		<Global
			styles={css`
				@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
				html {
					height: 100%;
				}
				body {
					background: #e5e5e5;
					margin: 0;
					padding: 0;
					height: 100%;
					font-family: 'Montserrat', sans-serif;
				}
				#___gatsby, #gatsby-focus-wrapper {
						height: 100%;
				}
			`}
		/>
	)
}

